import { graphql } from "gatsby"

import Page from "../views/Page"

export const query = graphql`
  query Page($pageSlug: String!, $images: [String]!) {
    contentfulPage(slug: { eq: $pageSlug }) {
      contentful_id
      title
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      seoJsonSchema {
        internal {
          content
        }
      }
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
    }
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Page
