import React from "react"
import { Value } from "contentful-layout-field"
import GatsbyImage, { FluidObject } from "gatsby-image"
import SEO from "../components/boilerplate/seo"
import LayoutBuilder from "../components/LayoutBuilder"
import PageContainer from "../components/PageContainer"
import Banner from "../components/Banner"
import { createContentfulImageMap } from "../utils"
import { PageQuery } from "../../graphql-types"
import { bp } from "../utils/MediaQueries"

type PageProps = {
  data: PageQuery
  pageContext: {
    pageSlug: string
    layoutData: Value
  }
  location: Location
}

const Page: React.FC<PageProps> = ({ data, pageContext, location }) => {
  const imageMap = createContentfulImageMap(data.images)
  const seoJsonSchemaContent =
    data.contentfulPage?.seoJsonSchema?.internal?.content
  const jsonSchema: Record<any, string> | null = React.useMemo(() => {
    try {
      const json = seoJsonSchemaContent
        ? JSON.parse(seoJsonSchemaContent)
        : null
      return json
    } catch (e) {
      return null
    }
  }, [seoJsonSchemaContent])

  return (
    <>
      <SEO
        title={data.contentfulPage?.seoTitle || data.contentfulPage?.title!}
        description={data.contentfulPage?.seoDescription?.seoDescription || ""}
        location={location}
        json={jsonSchema ? [jsonSchema] : undefined}
      />
      {data.contentfulPage?.banner?.fluid && (
        <Banner
          image={data.contentfulPage?.banner?.fluid as FluidObject}
          mobileImage={
            (data.contentfulPage?.mobileBanner?.fluid as FluidObject) ||
            undefined
          }
          url={data.contentfulPage?.bannerSlug}
          paddingTop={bp(0, 0)}
        />
      )}
      <PageContainer>
        <LayoutBuilder data={pageContext.layoutData} imageMap={imageMap} />
      </PageContainer>
    </>
  )
}

export default Page
